.promobi-about {
    .section-2 {
        @apply
            before:bg-pattern-triangles-sm
            md:before:bg-pattern-triangles
            before:bg-no-repeat
            before:bg-cover
            md:before:bg-auto
            md:before:bg-[position:top_1860px_center]
            before:absolute
            md:before:left-[-52px]
            before:left-0
            md:before:top-0
            before:top-[158rem]
            before:w-full
            before:md:max-h-full
            before:max-h-[384px]
            before:h-full;
        .grid {
            .grid-columns {
                @media (max-width: 1280px) {
                    grid-column-start: 2 !important;
                }
                @media (max-width: 1150px) {
                    grid-column-end: 12 !important;
                }
            }
        }
        .values-and-priciples {
            div.card {
                @apply min-h-[403px] md:max-w-[310px] overflow-hidden bg-dark-001 bg-no-repeat bg-cover;
                &:hover {
                    .content-wrapper {
                        @apply top-[0] transition-all ease-out duration-300 bg-[rgba(0,0,0,0.8)] #{!important};
                    }
                }
            }
        }
        .profiles {
            .img-wrapper img.profile-img {
                @apply max-w-[273px] max-h-[332px] relative top-[-60px] mx-auto;
            }
        }
        @media (max-width: 992px) {
            &::before {
                display: none;
            }
        }
    }
}

