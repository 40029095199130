.promobi-home {
  .hero {
    header {
      span.gradiant-text {
        @apply bg-text-gradiant animate-text-gradiant bg-[length:300%] bg-clip-text;
        -webkit-text-fill-color: transparent;
      }
    }
    .img-patterns {
      @apply max-w-[133px] max-h-[155px] md:max-w-[199px] md:max-h-[231px] rotate-[25deg] md:rotate-[0deg];
    }
    &::before {
      content: "";
      @apply absolute w-[92px] h-[156px] bg-great-place-to-work-badge bg-no-repeat
            top-[0] right-[28%] md:right-[25%] lg:right-[2%] bg-[length:92px_156px] bg-[position:right_top] z-[99];
      @media (max-width: 492px) {
        right: 12%;
      }
    }
  }
  .team-quotes-wrapper {
    ul.teams-list {
      li {
        @apply hidden md:block;
        &.active {
          @apply block font-semibold;
          span:nth-child(1) {
            @apply block;
          }
        }
        span:nth-child(1) {
          @apply hidden;
          svg.progress-spinner {
            transform: rotateY(-180deg) rotateZ(-90deg);
            circle {
              stroke-dasharray: 60px;
              stroke-dashoffset: 0px;
              stroke-linecap: round;
              stroke-width: 1px;
              stroke: #000;
              fill: none;
              @apply animate-progress-circle;
            }
          }
        }
      }
    }

    // stop the progress spinner when the animation is paused
    &.paused {
      svg.progress-spinner circle {
        animation: none !important;
      }
    }

    .quote-wrapper {
      @apply hidden;
      &.active {
        @apply block;
      }
      svg {
        @media (max-width: 540px) {
          @apply w-full;
        }
      }
      blockquote {
        @apply before:bg-quote
                before:bg-no-repeat
                before:absolute
                before:w-[45px]
                before:h-[29px]
                before:max-w-[45px]
                before:left-[-40px]
                before:top-[-40px];

        @media (max-width: 480px) {
          @apply text-[1rem] top-[90px] #{!important};
        }
      }
      .avatar-title-wrapper {
        span img {
          @apply max-w-[43px] max-h-[42px];
        }
      }
    }

    .engineering,
    .design {
      svg path {
        fill: theme("colors.yellow-003");
      }
      span.dot {
        @apply bg-yellow-002;
      }
    }
    .product-marketing,
    .qa-team {
      svg path {
        fill: theme("colors.cyan-001");
      }
      span.dot {
        @apply bg-cyan-300;
      }
    }
    .customer-support,
    .human-resource {
      svg path {
        fill: theme("colors.pink-001");
      }
      span.dot {
        @apply bg-pink-300;
      }
    }
    .success-team,
    .sales-team {
      svg path {
        fill: theme("colors.green-002");
      }
      span.dot {
        @apply bg-emerald-200;
      }
    }
  }
  .promobi-ten-years-celebration {
    &::before {
      content: "";
      @apply absolute w-[92px] h-[156px] bg-great-place-to-work-badge bg-no-repeat top-[0] right-[28%] md:right-[3%] lg:right-[2%] bg-[length:92px_156px] bg-[position:right_top] z-[99];
      @media (max-width: 492px) {
        right: 1%;
      }
    }
  }
}
