.navbar {
  #btn-navbar-toggle {
    span.icon-bar {
      @apply block mb-1 bg-dark-005 transition-transform duration-200 w-[22px];
      &:nth-child(1),
      &:nth-child(3) {
        @apply transform rotate-0;
      }
      &:nth-child(2) {
        @apply opacity-100;
      }
    }
    &.open {
      span.icon-bar {
        &:nth-child(1) {
          @apply rotate-45 origin-[10%_10%];
        }
        &:nth-child(2) {
          @apply opacity-0;
          filter: alpha(opacity=0);
        }
        &:nth-child(3) {
          @apply -rotate-45 origin-[10%_90%];
        }
      }
    }
    &.open + #navbar-menu {
      @apply flex #{!important};
    }
  }
  p.text-logo.text-white {
    svg path:nth-of-type(1), svg path:nth-last-of-type(1) {
      fill: var(--color-white);
    }
  }
  &.bg-white {
    p.text-logo,
    #navbar-menu .nav-item {
      @apply text-black #{!important};
    }
    p.text-logo {
      svg path:nth-of-type(1), svg path:nth-last-of-type(1) {
        fill: var(--color-black);
      }
    }
  }
  li.dropdown {
    @apply before:absolute before:w-full xl:before:h-[70px] before:top-0 before:left-0;
    ul.dropdown-menu {
      @apply bg-white p-3 mb-8 lg:mb-0 rounded-md relative lg:absolute top-[15px] left-0
            lg:top-[60px] lg:left-[-50px] lg:w-max hidden shadow-[0px_4px_24px_rgba(0,_0,_0,_0.05)];
    }
    &:hover {
      .dropdown-menu {
        @apply block #{!important};
      }
    }
  }
  @media (min-width: 1200px) and (max-width: 1336px) {
    #navbar-menu > ul {
      @apply pr-[60px];
    }
  }
  @media (max-width: 992px) {
    @apply bg-white;
  }
}
