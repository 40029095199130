.customer-logos {
    $logo-width: 100%;
    $logo-height: 65px;

    $logos: (
        (byjus 0px 0px $logo-width $logo-height),
        (jio 0px -66px $logo-width $logo-height),
        (pharmeasy 0px -129px $logo-width $logo-height),
        (saint-gobain 0px -197px $logo-width $logo-height),
        (decathlon 0px -260px $logo-width $logo-height),
        (pizza-portal 0px -326px $logo-width $logo-height),
        (netstar 0px -388px $logo-width $logo-height),
        (radisson-blu 0px -454px $logo-width $logo-height),
        (talabat 0px -521px $logo-width $logo-height),
        (asiacell 0px -588px $logo-width $logo-height),
        (nhs 0px -654px $logo-width $logo-height),
        (magna 0px -716px $logo-width $logo-height)
    );
    @each $tuple in $logos {
        $class: nth($tuple, 1);
        $left: nth($tuple, 2);
        $top: nth($tuple, 3);
        $width: nth($tuple, 4);
        $height: nth($tuple, 5);

        .#{$class} {
            background-position: $left $top;
        }
    }
    @media (max-width: 492px) {
        @apply grid-cols-2 max-w-[320px] mx-auto #{!important};
    }
}

.technologies-logos {
    $logo-width: 100%;
    $logo-height: 61px;

    $logos: (
        (rails 0px -1168px $logo-width $logo-height),
        (ruby 0px -1093px $logo-width $logo-height),
        (hotwire 0px -1935px $logo-width $logo-height),
        (nodejs 0px -1396px $logo-width $logo-height),
        (java 0px -934px $logo-width $logo-height),
        (python 0px -1008px $logo-width $logo-height),
        (postgresql 0px -1320px $logo-width $logo-height),
        (mysql 0px -774px $logo-width $logo-height),
        (sqlite 0px -538px $logo-width $logo-height),
        (git 0px -1628px $logo-width $logo-height),
        (ansible 0px -468px $logo-width $logo-height),
        (terraform 0px -392px $logo-width $logo-height),
        (elk 0px -313px $logo-width $logo-height),
        (prometheus 0px -234px $logo-width $logo-height),
        (graphana 0px -157px $logo-width $logo-height),
        (c-sharp 0px -1866px $logo-width $logo-height),
        (swift 0px -1249px $logo-width $logo-height),
        (html5 0px -1793px $logo-width $logo-height),
        (es-6 0px -1715px $logo-width $logo-height),
        (javascript 0px -1559px $logo-width $logo-height),
        (angular 0px -1483px $logo-width $logo-height),
        (vue 0px -623px $logo-width $logo-height),
        (react 0px -703px $logo-width $logo-height),
        (gatsby 0px -859px $logo-width $logo-height),
        (stimulusjs 0px -78px $logo-width $logo-height),
        (tailwindcss 0px 0px $logo-width $logo-height)
    );
    @each $tuple in $logos {
        $class: nth($tuple, 1);
        $left: nth($tuple, 2);
        $top: nth($tuple, 3);
        $width: nth($tuple, 4);
        $height: nth($tuple, 5);

        .#{$class} {
            background-position: $left $top;
        }
    }
}

.badges {
    $logo-width: 120px;
    $logo-height: 106px;

    $logos: (
        (easiest-admin 0px 0px $logo-width $logo-height),
        (easiest-business 0px -278px $logo-width $logo-height),
        (g2-leader-2020 0px -139px $logo-width $logo-height),
        (g2-leader-2021 0px -416px $logo-width $logo-height),
        (g2-leader-2022 0px -548px $logo-width $logo-height),
        (g2-leader-2023 0px -681px $logo-width $logo-height),
        (g2-leader-2024 0px -818px $logo-width $logo-height),
        (best-support-2024 0px -957px $logo-width $logo-height),
        (g2-users-love 0px -1093px $logo-width $logo-height),
        (digital-best-mdm 0px -1230px $logo-width $logo-height),
        (getapp 0px -1369px $logo-width $logo-height),

        (gdpr 0px -1504px $logo-width $logo-height),
        (soc2 0px -1775px $logo-width $logo-height),
        (hipaa 0px -1892px $logo-width $logo-height),
        (iso-27001 0px -1635px $logo-width $logo-height),
        (iso-9001 0px -2010px $logo-width $logo-height),
    );
    @each $tuple in $logos {
        $class: nth($tuple, 1);
        $left: nth($tuple, 2);
        $top: nth($tuple, 3);
        $width: nth($tuple, 4);
        $height: nth($tuple, 5);

        .#{$class} {
            background-position: $left $top;
            width: $logo-width;
            height: $logo-height;
        }
    }
}