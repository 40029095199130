@layer base{
    @font-face {
        font-family: 'Aclonica';
        font-weight: 400;
        src: url('./stylesheets/fonts/Aclonica-Regular.woff2') format("woff2");
        font-display: swap;
    }
    html {
        font-family: theme('fontFamily.hind'), theme('fontFamily.sans');
        -webkit-tap-highlight-color: transparent;
        -webkit-font-feature-settings: "kern", "ss03";
        font-feature-settings: "kern", "ss03";
        -webkit-text-size-adjust: 100%;
        @apply antialiased scroll-smooth;
        body {
            h1, h2, h3 {
                font-family: theme('fontFamily.hind'), theme('fontFamily.sans');
            }
            p {
                @apply font-medium;
                &.text-lg {
                    @apply font-normal;
                }
            }
            .blur-white {
                background: rgba(255, 255, 255, 0.8);
                filter: blur(25px);
            }
        }
    }
}

@layer components {
    .btn {
        &.btn-blue {
            @apply bg-blue-002 text-sm text-white font-semibold py-3 px-5 rounded;
        }
        &.btn-learn-more {
            @apply relative text-sm font-semibold;
            &::after {
                content: '';
                background-image: url('./images/common/arrow-black.svg');
                @apply bg-no-repeat bg-right absolute top-0 -right-11 w-[35px] h-full;
            }
            &.text-blue-001 {
                &::after {
                    background-image: url('./images/common/arrow-blue.svg');
                }
            }
            &.text-white {
                &::after {
                    background-image: url('./images/common/arrow-white.svg');
                }
            }
            &.text-blue-002 {
                &::after {
                    background-image: url('./images/common/arrow-dark-blue.svg');
                }
            }
        }
        &.btn-outline {
            @apply relative flex items-center justify-center bg-white border border-blue-002 rounded-sm text-[0.875rem] font-semibold text-blue-002 px-5 min-h-[30px] w-[fit-content];
        }
        &.btn-get-directions {
            @apply w-[max-content] border-b border-dashed border-amber-001 text-light after:bg-arrow-up-right after:bg-no-repeat after:bg-right after:absolute after:right-[-20px] after:top-[5px] after:w-[14px] after:h-[14px] max-h-[25px];        
        }
    }
    .card {
        @apply bg-white py-6 px-8 shadow;
    }
    .badge {
        @apply px-1.5 py-0.5 text-[0.75rem] font-semibold rounded-sm tracking-wide;
        &:nth-child(1) {
            @apply bg-blue-01 text-blue-004
        }
        &:nth-child(2) {
            @apply bg-green-001 text-green-005
        }
        &:nth-child(3) {
            @apply bg-brown-001 text-brown-005
        }
        &:nth-child(4) {
            @apply bg-violet-001 text-violet-004
        }
    }
    a {
        @apply text-blue-002;
    }
    .text-light {
        @apply text-gray-002;
    }
}